import {
    Button,
    ButtonColor,
    ButtonSize,
    DialogSize,
    Form,
    FormSubmitButton,
    Modal,
    Paragraph,
    TextArea,
    TextButton,
    TextField,
    TextFieldWidth,
    emailValidator,
    phoneValidator,
    textValidator,
    useForm,
    UseFormResult,
    ZIndexTokens,
    Icon,
    EverColor,
} from "design-system";
import * as User from "Everlaw/User";
import * as React from "react";
import { ReactElement, useEffect, useState } from "react";

interface KeyValuePair {
    [key: string]: string;
}

// Declare any Marketo Form related functions here.
// Marketo API Reference: https://developers.marketo.com/javascript-api/forms/api-reference/
interface MktoFormObject {
    onSuccess(callback: () => boolean): unknown;
    submit(): MktoFormObject;
    setValues(vals: KeyValuePair): unknown;
    addHiddenFields(values: KeyValuePair): unknown;
    validate(): boolean;
}

// An interface representing the values in the form.
interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    caseDetails: string;
}
// Marketo Forms 2 API. Declare any other functions required here.
// Marketo API Reference: https://developers.marketo.com/javascript-api/forms/api-reference/
interface MktoForms2 {
    loadForm(
        url: string,
        munchkinId: string,
        formId: string,
        callback?: (form: MktoFormObject) => void,
    ): void;
    getForm(formId: number | string): MktoFormObject;
    allForms(): [MktoFormObject];
}

// Marketo Form is loaded in headHeader.jsp and available as window.MktoForms2 once loaded.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MktoForms2: MktoForms2 = (window as any).MktoForms2;
interface NewCaseFormProps {
    showFormLoadError: () => unknown;
    showFormValidationError: () => unknown;
    setDisableFormSubmit: (_: boolean) => void;
}
function NewCaseForm({
    showFormLoadError,
    showFormValidationError,
    setDisableFormSubmit,
}: NewCaseFormProps): ReactElement<null> {
    const marketoFormId = 1606;
    const formResult: UseFormResult<FormValues, React.ReactNode> = useForm<FormValues>({
        initialValues: {
            firstName: User.me?.firstName ?? "",
            lastName: User.me?.lastName ?? "",
            email: User.me?.email ?? "",
            phone: "",
            caseDetails: "",
        },
        validator: {
            firstName: textValidator({
                name: "firstName",
                required: true,
                requiredFieldErrorMessage: "This field is required",
            }),
            lastName: textValidator({
                name: "lastName",
                required: true,
                requiredFieldErrorMessage: "This field is required",
            }),
            email: emailValidator({
                name: "email",
                required: true,
                incorrectValueErrorMessage: "Invalid email",
                requiredFieldErrorMessage: "This field is required",
            }),
            phone: phoneValidator({
                name: "phone",
                required: true,
                incorrectValueErrorMessage: "Invalid phone number",
                requiredFieldErrorMessage: "This field is required",
            }),
        },
        onSubmit: () => {
            const formValues = formResult.values;
            const myForm = MktoForms2.getForm(marketoFormId);
            if (!myForm) {
                // Marketo form failed to load.
                showFormLoadError();
                return;
            }
            myForm.setValues({ FirstName: formValues.firstName });
            myForm.setValues({ LastName: formValues.lastName });
            myForm.setValues({ Email: formValues.email });
            myForm.setValues({ Phone: formValues.phone });
            myForm.setValues({ Inbound_Message__c: formValues.caseDetails });
            if (User.me && User.me.primaryOrg) {
                myForm.addHiddenFields({ organization: User.me.primaryOrg.name });
            }
            if (!myForm.validate()) {
                // Marketo form validation failed even though Everlaw validation passed.
                showFormValidationError();
                // Reset values of fields that may have caused the error.
                formValues.phone = "";
                formValues.email = User.me?.email ?? "";
                return;
            }
            myForm.submit();
        },
    });
    useEffect(() => {
        if (formResult && Object.keys(formResult.errors).length) {
            setDisableFormSubmit(true);
        } else {
            setDisableFormSubmit(false);
        }
    }, [formResult.errors]);
    return (
        <Form onSubmit={formResult.submit} id={"new-case-form"}>
            <div className={"bb-form__main"}>
                <TextField
                    name="firstName"
                    value={formResult.values.firstName}
                    error={formResult.blurred.firstName && !!formResult.errors.firstName}
                    errorMessage={formResult.errors.firstName}
                    width={TextFieldWidth.FULL}
                    horizontal={false}
                    onBlur={(e) => formResult.blur("firstName")}
                    onChange={(e) => formResult.change("firstName", e.target.value)}
                    label="First name"
                    required={true}
                />
                <TextField
                    name="lastName"
                    value={formResult.values.lastName}
                    error={formResult.blurred.lastName && !!formResult.errors.lastName}
                    errorMessage={formResult.errors.lastName}
                    width={TextFieldWidth.FULL}
                    horizontal={false}
                    onBlur={(e) => formResult.blur("lastName")}
                    onChange={(e) => formResult.change("lastName", e.target.value)}
                    label="Last name"
                    required={true}
                />
                <TextField
                    name="email"
                    value={formResult.values.email}
                    error={formResult.blurred.email && !!formResult.errors.email}
                    errorMessage={formResult.errors.email}
                    horizontal={false}
                    width={TextFieldWidth.FULL}
                    onBlur={(e) => formResult.blur("email")}
                    onChange={(e) => formResult.change("email", e.target.value)}
                    label="Email"
                    subLabel="(Used to login to Everlaw)"
                    placeholder={"Enter email"}
                    required={true}
                />
                <TextField
                    name="phone"
                    value={formResult.values.phone}
                    error={formResult.blurred.phone && !!formResult.errors.phone}
                    errorMessage={formResult.errors.phone}
                    horizontal={false}
                    width={TextFieldWidth.FULL}
                    onBlur={(e) => formResult.blur("phone")}
                    onChange={(e) => formResult.change("phone", e.target.value)}
                    label="Phone number"
                    placeholder={"Enter phone number"}
                    required={true}
                />
                <TextArea
                    name="caseDetails"
                    value={formResult.values.caseDetails}
                    error={formResult.blurred.caseDetails && !!formResult.errors.caseDetails}
                    errorMessage={formResult.errors.caseDetails}
                    horizontal={false}
                    onBlur={(e) => formResult.blur("caseDetails")}
                    onChange={(e) => formResult.change("caseDetails", e.target.value)}
                    label="New case details"
                    placeholder={
                        "Details about the new case, such as database size, data migration needs, or billing information"
                    }
                    required={false}
                />
            </div>
        </Form>
    );
}

interface CreateModalProps {
    buttonStyle: string;
    isHelpPage: boolean;
}

// Used to swap the onSuccess callback of the Marketo form between database settings and help pages.
let formOnSuccessCallback = () => false;

export function CreateBuyNewCaseForm({ buttonStyle, isHelpPage }: CreateModalProps) {
    const [modalVisible, setModalVisible] = useState(false);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [loadErrorVisible, setLoadErrorVisible] = useState(false);
    const [validationErrorVisible, setValidationErrorVisible] = useState(false);
    const [disableFormSubmit, setDisableFormSubmit] = useState(false);

    const formId = 1606;
    const showFormLoadError = () => {
        setLoadErrorVisible(true);
    };
    const showFormValidationError = () => {
        setValidationErrorVisible(true);
    };
    const newForm = (
        <NewCaseForm
            showFormLoadError={showFormLoadError}
            showFormValidationError={showFormValidationError}
            setDisableFormSubmit={setDisableFormSubmit}
        />
    );
    formOnSuccessCallback = () => {
        if (modalVisible) {
            setModalVisible(false);
            if (!confirmationVisible) {
                setConfirmationVisible(true);
            }
        }
        ga_event("MarketoForm", "Buy new case request");
        return false;
    };
    // Load marketo form if there is no form on the current page everytime the form dialog is opened.
    // If Marketo form load error is showed to the user, try loading the form again.
    useEffect(() => {
        const myForm = MktoForms2.getForm(formId);
        if (!myForm) {
            MktoForms2.loadForm("//go.everlaw.com", "314-QPM-328", "1606", (myForm) => {
                if (myForm) {
                    myForm.onSuccess(() => formOnSuccessCallback());
                }
            });
        }
    }, [modalVisible, loadErrorVisible]);

    const formContent = (
        <>
            <Paragraph>
                Complete the information below to fast-track adding a new case to Everlaw.{" "}
                <span className={"bb-text--bold"}>Please note this is not the Support portal.</span>{" "}
                For support issues, please email support@everlaw.com or click "Message us".
            </Paragraph>
            {newForm}
        </>
    );
    const successfulContent = (
        <Paragraph>
            Thanks for submitting your request! An Everlaw team member will reach out shortly.
        </Paragraph>
    );
    const formLoadErrorContent = (
        <Paragraph>
            An unexpected error occurred when submitting the request. Please try again. If the issue
            persists, please contact Everlaw support for assistance.
        </Paragraph>
    );
    const formValidationErrorContent = (
        <Paragraph>
            Your request could not be submitted due to validation error. Please check the email or
            phone number fields and try again.
        </Paragraph>
    );
    const helpPageElem = (
        <TextButton
            children={"Buy a new case"}
            icon={<Icon.Database color={EverColor.WHITE} />}
            className={buttonStyle}
            size={ButtonSize.SMALL}
            onClick={() => {
                setModalVisible(true);
            }}
        />
    );
    const databaseSettingPageElem = (
        <Button
            children={"Buy a new case"}
            className={buttonStyle}
            color={ButtonColor.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={() => {
                setModalVisible(true);
            }}
        />
    );
    const openCaseButton = isHelpPage ? helpPageElem : databaseSettingPageElem;
    return (
        <>
            {openCaseButton}
            <Modal
                size={DialogSize.MD}
                primaryButton={
                    <FormSubmitButton
                        form={"new-case-form"}
                        children={"Submit request"}
                        disabled={disableFormSubmit}
                    />
                }
                secondaryButton={"Cancel"}
                baseZIndex={ZIndexTokens.DIALOG_HELP + 1} // Greater z-index than help menu, so that the dialog shows up above the help menu (z-index 951).
                visible={modalVisible}
                onHide={() => setModalVisible(false)}
                onCancel={() => setModalVisible(false)}
                className={"open-new-case"}
                title="Buy a new case"
            >
                {formContent}
            </Modal>
            <Modal
                size={DialogSize.SM}
                primaryButton={"OK"}
                secondaryButton={null}
                visible={confirmationVisible}
                onHide={() => setConfirmationVisible(false)}
                onComplete={() => setConfirmationVisible(false)}
                className={"open-new-case"}
                title="Buy a new case"
            >
                {successfulContent}
            </Modal>
            <Modal
                size={DialogSize.SM}
                primaryButton={"OK"}
                secondaryButton={null}
                visible={loadErrorVisible}
                onHide={() => setLoadErrorVisible(false)}
                onComplete={() => setLoadErrorVisible(false)}
                title="Error"
            >
                {formLoadErrorContent}
            </Modal>
            <Modal
                size={DialogSize.SM}
                primaryButton={"OK"}
                secondaryButton={null}
                visible={validationErrorVisible}
                onHide={() => setValidationErrorVisible(false)}
                onComplete={() => setValidationErrorVisible(false)}
                title="Validation error"
            >
                {formValidationErrorContent}
            </Modal>
        </>
    );
}
