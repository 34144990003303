import * as ColorUtil from "Everlaw/ColorUtil";

module HighlightColor {
    export enum Color {
        WHITE = "WHITE",
        ANY = "ANY",
        BLACK = "BLACK",
        BLUE = "BLUE",
        GREEN = "GREEN",
        PINK = "PINK",
        YELLOW = "YELLOW",
        ORANGE = "ORANGE",
    }

    export function toIconClass(c: Color, full = false, large = false) {
        return (
            (full ? "icon_" : "")
            + (c === Color.BLACK ? "redact" : "highlighter-" + c.toLowerCase())
            + (!large ? "-20" : "")
        );
    }

    export function toLabel(c: Color) {
        return c[0] + c.slice(1).toLowerCase();
    }
    export const COLOR_DEFAULT = Color.YELLOW;
    export const COLOR_REDACTION = Color.BLACK;

    export function highlightColors(includeAny = false, includeNone = false): Color[] {
        return Object.keys(Color)
            .map((key) => Color[key])
            .filter(
                (color) =>
                    !(
                        color === COLOR_REDACTION
                        || (color === Color.WHITE && !includeNone)
                        || (color === Color.ANY && !includeAny)
                    ),
            );
    }

    function colorRGB(color: Color): ColorUtil.RgbaColorValues {
        switch (color) {
            case Color.BLACK:
                return { r: 0, g: 0, b: 0 };
            case Color.BLUE:
                return { r: 13, g: 90, b: 177 };
            case Color.GREEN:
                return { r: 60, g: 205, b: 0 };
            case Color.PINK:
                return { r: 255, g: 25, b: 135 };
            case Color.YELLOW:
                return { r: 248, g: 228, b: 0 };
            case Color.ORANGE:
                return { r: 255, g: 135, b: 0 };
            default:
                throw Error("illegal color " + color.toString());
        }
    }

    export function getColor(color: Color, alpha?: number) {
        const values = colorRGB(color);
        values.a = alpha;
        return new ColorUtil.Color(values);
    }
}

export = HighlightColor;
